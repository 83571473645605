import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

//Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/core
import Grid from "@material-ui/core/Grid";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Close from "@material-ui/icons/Close";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TimerOffIcon from "@material-ui/icons/TimerOff";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";

//Autorizacion
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Muted from "components/Typography/Muted.js";

//Form
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { blackColor } from "assets/jss/material-dashboard-pro-react";
import { Fastfood } from "@material-ui/icons";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DetalleReporte(props) {
  const classes = useStyles();

  const history = useHistory();

  const initialState = {
    isSubmitting: false,
    errorMessage: null,
    success: false,
    userName: "",
    userCompany: "",
    detalle: "",
  };
  // MODALS
  const [notaModal, setNotaModal] = React.useState(false);
  const [reporteTecModal, setReporteTecModal] = React.useState(false);
  const [categoriaModal, setCategoriaModal] = React.useState(false);
  const [asignacionModal, setAsignacionModal] = React.useState(false);
  const [rechazarModal, setRechazarModal] = React.useState(false);
  const [pausarModal, setPausarModal] = React.useState(false);
  const [solicitudPausaModal, setSolicitudPausaModal] = React.useState(false);
  const [tc, setTC] = React.useState(false);
  const [rechazoSolicitudModal, setRechazoSolicitudModal] = React.useState(
    false
  );

  // CORE
  const [reporte, setReporte] = useState();
  const [changes, setChanges] = useState(1);
  const [data, setData] = useState(initialState);
  const [codReporte, setCodReporte] = useState(
    props.match.params.codigoReporte
  );
  //VARIABLES
  const [textNote, setTextNote] = useState("");
  const [textAsignacion, setTextAsignacion] = useState([]);
  const [textAsignacionU, setTextAsignacionU] = useState("");
  const [txtJustificacion, setTxtJustificacion] = useState("");
  const [textReporteTec, setTextReporteTec] = useState("");
  const [textMateriales, setTextMateriales] = useState("");
  const [textHorasHombre, setTextHorasHombre] = useState(0);
  const [textHorasExtra, setTextHorasExtra] = useState(0);
  const [categoriaOriginal, setCategoriaOriginal] = useState(" ");
  const [multipleSelect, setMultipleSelect] = React.useState([]);
  const [txtNotificacion, setTxtNotificacion] = useState("");
  const [personalAsignado, setPersonalAsignado] = useState([]);
  const [empresa, setEmpresa] = useState("");
  const [duraciones, setDuraciones] = useState([]);
  const [textDuracion, setTextDuracion] = useState("");
  const [textPausa, setTextPausa] = useState("");
  const [textSolicitudPausa, setTextSolicitudPausa] = useState("");
  const [textRechazoSolicitud, setTextRechazoSolicitud] = useState("");
  const [users, setUsers] = useState([]);

  const userRole = localStorage.userConfig;

  // CARGA DEL REPORTE
  const fetchData = async () => {
    console.log(localStorage);

    setAuthToken(localStorage.jwtToken);
    const result = await axios.get(
      `https://epstool.com/api/reporte/${codReporte}`
      // `http://localhost:5005/api/reporte/${codReporte}`
    );

    const resultDuracion = await axios.get(
      `https://epstool.com/api/reporte/duracion`
      // `http://localhost:5005/api/reporte/duracion`
    );

    const resultUsers = await axios.get(
      `https://epstool.com/api/users/userstec`
      // `http://localhost:5005/api/users/userstec`
    );

    setReporte(result.data);
    setMultipleSelect(result.data.categoria);
    setCategoriaOriginal(result.data.categoria);
    const decoded = jwt_decode(localStorage.jwtToken);
    setEmpresa(decoded.company);

    setDuraciones(resultDuracion.data);
    setUsers(resultUsers.data);
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, [changes]);

  const showNotification = () => {
    setTC(true);
    setTimeout(function() {
      setTC(false);
    }, 6000);
  };

  // }
  // MANEJO DE DROPDOWN
  const handleMultiple = (event) => {
    setMultipleSelect(event.target.value);
  };

  // MANEJO DE DROPDOWN
  const handleAsignacion = (event) => {
    setPersonalAsignado(event.target.value);
  };

  // GUARDAR NOTA
  const handleNotaSubmit = async (event) => {
    event.preventDefault();
    const decoded = jwt_decode(localStorage.jwtToken);
    const notaToSave = {
      nota: rechazoSolicitudModal
        ? "Solicitud de pausa realizada por " +
          reporte.solicitudPausa[0].comentario +
          " fue rechazada por la siguiente razón: " +
          textNote
        : textNote,
      userName: decoded.name,
      userCompany: decoded.company,
    };
    await axios
      .post(`https://epstool.com/api/reporte/nota/${codReporte}`, notaToSave)
      // .post(`http://localhost:5005/api/reporte/nota/${codReporte}`, notaToSave)
      .then((res) => {
        setTxtNotificacion("Solicitud realizada con éxito");
        setTextNote("");
        setNotaModal(false);
        setRechazoSolicitudModal(false);
        setChanges(changes + 1);
        showNotification();
      })
      .catch((error) => {
        setData({
          ...data,
          success: false,
          isSubmitting: false,
          errorMessage: error.message || error.statusText,
        });
      });
  };

  // GUARDAR SOLICITUD PAUSA
  const handleSolicitudPausaSubmit = async (event) => {
    event.preventDefault();
    const decoded = jwt_decode(localStorage.jwtToken);
    const solicitudToSave = {
      comentario: textSolicitudPausa,
      userAsigna: decoded.id,
    };
    await axios
      .post(
        `https://epstool.com/api/reporte/solicitud-pausa/${codReporte}`,
        solicitudToSave
      )
      // .post(
      //   `http://localhost:5005/api/reporte/solicitud-pausa/${codReporte}`,
      //   solicitudToSave
      // )
      .then((res) => {
        setTxtNotificacion("Solicitud realizada con éxito");
        setTextSolicitudPausa("");
        setSolicitudPausaModal(false);
        setChanges(changes + 1);
        showNotification();
      })
      .catch((error) => {
        setData({
          ...data,
          success: false,
          isSubmitting: false,
          errorMessage: error.message || error.statusText,
        });
      });
  };

  // ACEPTA SOLICITUD DE PAUSA
  const handleAceptaSolicitudPausa = async (event) => {
    event.preventDefault();

    setTextPausa(reporte.solicitudPausa[0].comentario);

    await axios.put(
      `https://epstool.com/api/reporte/acepta-solicitud-pausa/${codReporte}/${reporte.solicitudPausa[0]._id}`
    );
    // .put(
    //   `http://localhost:5005/api/reporte/acepta-solicitud-pausa/${codReporte}/${reporte.solicitudPausa[0]._id}`
    // );

    // setTextNote('Solicitud de pausa realizada por ' + reporte.solicitudPausa[0].comentario + ' fue aceptada');

    handlePauseSubmit(event);

    handleNotaSubmit(event);
  };

  //RECHAZO SOLICITUD PAUSA
  const handleRechazoSolicitudSubmit = async (event) => {
    event.preventDefault();
    const decoded = jwt_decode(localStorage.jwtToken);

    await axios.put(
      `https://epstool.com/api/reporte/rechazar-solicitud-pausa/${codReporte}/${reporte.solicitudPausa[0]._id}`
    );
    // .put(
    //   `http://localhost:5005/api/reporte/rechazar-solicitud-pausa/${codReporte}/${reporte.solicitudPausa[0]._id}`
    // );

    // textNote = 'Solicitud de pausa realizada por ' + reporte.solicitudPausa[0].comentario + ' fue rechazada por la siguiente razón: ' + textNote;

    handleNotaSubmit(event);
  };

  // GUARDAR ASIGNACION
  const handleAsignacionSubmit = async (event) => {
    event.preventDefault();
    const decoded = jwt_decode(localStorage.jwtToken);

    const asignacioToSave = {
      asignacion: reporte.categoria === 1 ? textAsignacion : [textAsignacionU],
      userAsigna: decoded.id,
      userName: decoded.name,
      userCompany: decoded.company,
      duracion: reporte.categoria === 1 ? textDuracion : "",
      user: reporte.categoria === 1 ? textAsignacion : textAsignacionU,
    };
    console.log(asignacioToSave);
    await axios
      .post(
        `https://epstool.com/api/reporte/asignacion/${codReporte}`,
        asignacioToSave
      )
      // .post(
      //   `http://localhost:5005/api/reporte/asignacion/${codReporte}`,
      //   asignacioToSave
      // )
      .then((res) => {
        setTxtNotificacion("Asignación realizada con éxito");
        setAsignacionModal(false);
        setChanges(changes + 1);
        setTextAsignacion([]);
        setTextDuracion("");
        showNotification();
      })
      .catch((error) => {
        setData({
          ...data,
          success: false,
          isSubmitting: false,
          errorMessage: error.message || error.statusText,
        });
      });
  };

  // GUARDAR RECHAZAR
  const handleRechazarSubmit = async (event) => {
    event.preventDefault();
    const decoded = jwt_decode(localStorage.jwtToken);
    const rechazarToSave = {
      nota: txtJustificacion,
      userName: decoded.name,
      userCompany: decoded.company,
    };
    await axios
      .post(
        `https://epstool.com/api/reporte/rechazar/${codReporte}`,
        rechazarToSave
      )
      // .post(`/api/reporte/rechazar/${codReporte}`, rechazarToSave)
      .then((res) => {
        setTxtNotificacion("Rechazo de tiquete realizado con éxito");
        setRechazarModal(false);
        setCategoriaModal(false);
        setChanges(changes + 1);
        setTxtJustificacion("");
        showNotification();
      })
      .catch((error) => {
        setData({
          ...data,
          success: false,
          isSubmitting: false,
          errorMessage: error.message || error.statusText,
        });
      });
  };

  // GUARDAR REPORTE
  const handleReporteTecSubmit = async (event) => {
    event.preventDefault();
    const decoded = jwt_decode(localStorage.jwtToken);

    const reporteTecToSave = {
      userAsigna: decoded.id,
      otros: textReporteTec,
      userName: decoded.name,
      userCompany: decoded.company,
      materiales: textMateriales,
      horasHombre: textHorasHombre,
      horasExtra: textHorasExtra,
    };

    if (reporte.categoria === 1) {
      reporteTecToSave.asignado = reporte.asignar[0].asignacion;
    }

    await axios
      .post(
        `https://epstool.com/api/reporte/reportetec/${codReporte}`,
        // `http://localhost:5005/api/reporte/reportetec/${codReporte}`,
        reporteTecToSave
      )
      .then((res) => {
        setTxtNotificacion("Reporte técnico realizado con éxito");
        setReporteTecModal(false);
        setChanges(changes + 1);
        showNotification();
      })
      .catch((error) => {
        console.log(error.message);
        setData({
          ...data,
          success: false,
          isSubmitting: false,
          errorMessage: error.message || error.statusText,
        });
      });
  };

  // GUARDAR CAMBIO DE CATEGORIA
  const handleCategoriaSubmit = async (event) => {
    event.preventDefault();
    await axios
      .post(
        `https://epstool.com/api/reporte/categoria/${codReporte}/${multipleSelect}`
        // `/api/reporte/categoria/${codReporte}/${multipleSelect}`
      )
      .then((res) => {
        setTxtNotificacion("Cambio realizado con éxito");
        setCategoriaModal(false);
        setChanges(changes + 1);
        showNotification();
      })
      .catch((error) => {
        setData({
          ...data,
          success: false,
          isSubmitting: false,
          errorMessage: error.message || error.statusText,
        });
      });
  };

  // ABRIR TIQUETE
  const abrirTiquete = async () => {
    const decoded = jwt_decode(localStorage.jwtToken);
    const user = {
      userName: decoded.name,
      userCompany: decoded.company,
    };

    await axios
      .post(
        `https://epstool.com/api/reporte/abrirTiquete/${codReporte}`,
        // `http://localhost:5005/api/reporte/abrirTiquete/${codReporte}`,
        user
      )
      .then((res) => {
        // setCategoriaModal(false);
        setTxtNotificacion("Tiquete abierto con éxito");
        setChanges(changes + 1);
        showNotification();
      })
      .catch((error) => {
        setData({
          ...data,
          success: false,
          isSubmitting: false,
          errorMessage: error.message || error.statusText,
        });
      });
  };

  // PAUSAR TICKET
  const handlePauseSubmit = async (event) => {
    event.preventDefault();
    const decoded = jwt_decode(localStorage.jwtToken);

    const pausaToSave = {
      nota:
        textPausa !== ""
          ? textPausa
          : reporte.solicitudPausa[0].comentario
          ? reporte.solicitudPausa[0].comentario
          : "",
      userName: decoded.name,
      userCompany: decoded.company,
    };

    await axios
      .put(
        `https://epstool.com/api/reporte/pausarTiquete/${codReporte}`,
        pausaToSave
        // `http://localhost:5005/api/reporte/pausarTiquete/${codReporte}`,
        // pausaToSave
      )
      .then((res) => {
        setPausarModal(false);
        setChanges(changes + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //RENAUDAR TICKET
  const handleRenaudaSubmit = async (event) => {
    event.preventDefault();
    const decoded = jwt_decode(localStorage.jwtToken);

    const pausaToSave = {
      nota: textPausa,
      userName: decoded.name,
      userCompany: decoded.company,
    };

    await axios
      .put(
        `https://epstool.com/api/reporte/renaudarTiquete/${codReporte}`,
        pausaToSave
        // `http://localhost:5005/api/reporte/renaudarTiquete/${codReporte}`,
        // pausaToSave
      )
      .then((res) => {
        setChanges(changes + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goBack = () => {
    localStorage.userConfig === "1"
      ? history.push({ pathname: `../dashboard`, datas: { redirect: true } })
      : history.push({
          pathname: `../user-dashboard`,
          datas: { redirect: true },
        });
  };

  let reporteInfo;
  let paintNotes;
  let paintReportesTec;
  let paintAsignaciones;
  let paintSolicitudPausa;
  const decoded = jwt_decode(localStorage.jwtToken);
  let user = decoded;

  // CARGAR INFORMACION DEL REPORTE
  let timeAssign;
  const calculaTiempo = () => {
    timeAssign = Math.round(reporte.tiempoATerminado / 60 / 60);

    if (timeAssign == 0) {
      timeAssign = Math.round(reporte.tiempoATerminado / 60) + " minutos";
    } else {
      if (timeAssign > 48) {
        timeAssign = Math.round(timeAssign / 24) + " días";
      } else {
        timeAssign += " horas";
      }
    }
  };

  if (reporte === undefined) {
    reporteInfo = "Cargando...";
  } else {
    let humanDate = new Date(reporte.fechaCreacion * 1000);
    let adjuntos;
    let filesCounter = 0;
    let humanEstado;

    let humanDuration = reporte.tiempoATerminado / 60 / 60;

    switch (reporte.estado) {
      case 0:
        humanEstado = "Nuevo";
        break;
      case 1:
        humanEstado = "En proceso";
        break;
      case 2:
        humanEstado = "Terminado";
        break;
      case 3:
        humanEstado = "Pausado";
        break;
      default:
        break;
    }

    if (!reporte.files[0]) {
      adjuntos = (
        <GridItem>
          <h4>No tiene archivos adjuntos</h4>
        </GridItem>
      );
    } else {
      adjuntos = (
        <GridItem>
          <h4>Archivos adjuntos: </h4>
          <ul>
            {reporte.files.map((item) => (
              <li key={filesCounter}>
                <a href={item}>Archivo {(filesCounter = filesCounter + 1)}</a>
              </li>
            ))}
          </ul>
        </GridItem>
      );
    }
    reporteInfo = (
      <Card>
        <CardHeader color="primary" icon>
          <GridContainer>
            <GridItem xs={12} md={9}>
              <CardIcon color="success">
                <Assignment />
              </CardIcon>
              <Grid container spacing={3}>
                <Grid item xs={1}>
                  <ArrowBackIcon
                    onClick={() => {
                      goBack();
                    }}
                    style={{
                      width: 50,
                      height: 50,
                      color: blackColor,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <h3 className={classes.cardIconTitle}>
                    Reporte #{reporte.codigoReporte}
                  </h3>
                </Grid>
              </Grid>
            </GridItem>
            <GridItem xs={12} md={3}>
              <h4 className={classes.cardIconTitle}> Status: {humanEstado}</h4>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem>
              <h4 className={classes.cardIconTitle}>
                Fecha de creación: {humanDate.toLocaleString()}
              </h4>
            </GridItem>
          </GridContainer>
          {reporte.estado === 2 ? (
            <GridContainer>
              <GridItem>
                {calculaTiempo(humanDuration)}
                <h4 className={classes.cardIconTitle}>
                  Tiempo de duración: {timeAssign}
                </h4>
              </GridItem>
            </GridContainer>
          ) : (
            ""
          )}
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} md={8}>
              <h4>Detalle: </h4>
              <p>{reporte.detalle}</p>
            </GridItem>
            {/* ACCIONES */}

            <GridItem xs={12} md={4}>
              <div className={classes.cardHeader}>
                <h4 className={classes.cardTitle}>Acciones: </h4>
              </div>
              <div className={classes.cardContentLeft}>
                {userRole === "1" ? (
                  <div>
                    {reporte.estado >= 2 &&
                    reporte.estado !== 3 &&
                    user.codigoPrivilegio === 3 ? (
                      <Button
                        color="success"
                        className={classes.marginRight}
                        onClick={abrirTiquete}
                      >
                        Abrir tiquete
                      </Button>
                    ) : null}
                    {reporte.estado >= 2 ? (
                      <Button
                        disabled
                        color="success"
                        className={classes.marginRight}
                        onClick={() => setNotaModal(true)}
                      >
                        Crear Nota
                      </Button>
                    ) : (
                      <Button
                        color="success"
                        className={classes.marginRight}
                        onClick={() => setNotaModal(true)}
                      >
                        Crear Nota
                      </Button>
                    )}

                    {reporte.estado >= 1 ? (
                      <Button
                        disabled
                        color="success"
                        onClick={() => setAsignacionModal(true)}
                        className={classes.marginRight}
                      >
                        Asignar
                      </Button>
                    ) : (
                      <Button
                        color="success"
                        onClick={() => {
                          setAsignacionModal(true);
                        }}
                        className={classes.marginRight}
                      >
                        Asignar
                      </Button>
                    )}
                  </div>
                ) : (
                  ""
                )}
                {reporte.estado === 1 ? (
                  <Button
                    color="success"
                    onClick={() => setReporteTecModal(true)}
                    className={classes.marginRight}
                  >
                    Crear Rep. Técnico
                  </Button>
                ) : (
                  <Button
                    disabled
                    color="success"
                    onClick={() => setReporteTecModal(true)}
                    className={classes.marginRight}
                  >
                    Crear Rep. Técnico
                  </Button>
                )}
                {userRole === "2" &&
                reporte.estado === 1 &&
                reporte.categoria === 1 ? (
                  <Button
                    color="success"
                    onClick={() => setSolicitudPausaModal(true)}
                    className={classes.marginRight}
                  >
                    Solicitar pausa
                  </Button>
                ) : (
                  ""
                )}
                {userRole === "1" ? (
                  <div>
                    {reporte.estado >= 1 || user.codigoPrivilegio < 2 ? (
                      <Button
                        disabled
                        color="success"
                        onClick={() => setCategoriaModal(true)}
                        className={classes.marginRight}
                      >
                        Cambiar categoría
                      </Button>
                    ) : (
                      <Button
                        color="success"
                        onClick={() => setCategoriaModal(true)}
                        className={classes.marginRight}
                      >
                        Cambiar categoría
                      </Button>
                    )}
                    {reporte.estado === 1 && reporte.categoria === 1 ? (
                      <Button
                        color="success"
                        onClick={() => {
                          setPausarModal(true);
                          // pauseTicket()
                        }}
                        className={classes.marginRight}
                      >
                        Pausar tiquete
                      </Button>
                    ) : (
                      ""
                    )}
                    {reporte.estado === 3 && reporte.categoria === 1 ? (
                      <Button
                        color="success"
                        onClick={handleRenaudaSubmit}
                        className={classes.marginRight}
                      >
                        Renaudar tiquete
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>{adjuntos}</GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              {reporte.email === "" || reporte.email === undefined ? null : (
                <h4>El email del solicitante es: {reporte.email}</h4>
              )}
              <h4>Zona de QR: {reporte.zona}</h4>
              {reporte.diferenteZona === "No" ||
              reporte.diferenteZona === undefined ? null : (
                <h4>
                  Se ha detallado la siguiente ubicación:{" "}
                  {reporte.diferenteZona}
                </h4>
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
    // CARGAR NOTAS
    if (!reporte.notas[0]) {
      paintNotes = null;
    } else {
      paintNotes = reporte.notas.map((nota) => (
        <GridItem xs={12} md={12} key={nota._id}>
          <Card key={nota._id}>
            <CardHeader>
              <h4>
                Nota realizada el {new Date(nota.fecha * 1000).toLocaleString()}
              </h4>
            </CardHeader>
            <CardBody>
              <p>{nota.comentario}</p>
            </CardBody>
            <CardFooter>
              <p>
                Realizado por {nota.userName} - {nota.userCompany}
              </p>
            </CardFooter>
          </Card>
        </GridItem>
      ));
    }
    // CARGAR REPORTES
    if (!reporte.reporteTecnico[0]) {
      paintReportesTec = null;
    } else {
      paintReportesTec = reporte.reporteTecnico.map((reporteTec, i) => (
        <GridItem xs={12} md={12} key={i}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <AssignmentTurnedIn />
              </CardIcon>
              <br />
              <h4 className={classes.cardTitle}>
                {reporteTec.handleEstado}
                {" el "}
                {new Date(reporteTec.fecha * 1000).toLocaleString()}
              </h4>
            </CardHeader>
            <CardBody>
              <p>
                Reporte asignado a:{" "}
                {reporteTec.asignado
                  ? reporteTec.asignado.hasOwnProperty("name")
                    ? reporteTec.asignado.name
                    : reporteTec.asignado
                  : reporte.asignar[0].asignacion}
              </p>
              <p>Materiales usados: {reporteTec.materiales}</p>
              <p>
                Horas hombre naturales registradas:{" "}
                {reporteTec.horasHombreNormal}
              </p>
              <p>
                Horas hombre extra registradas: {reporteTec.horasHombreExtra}
              </p>
              <p>Otros comentarios: {reporteTec.otros}</p>
              {reporteTec.files[0] ? (
                <ul>
                  {reporteTec.files.map((item, index) => (
                    <li key={index}>
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Archivo {index + 1}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                "No tiene archivos adjuntos"
              )}
            </CardBody>
            <CardFooter>
              <p>
                Reporte realizado por{" "}
                {reporteTec.hasOwnProperty("userAsigna")
                  ? reporteTec.userAsigna.name +
                    " - " +
                    reporteTec.userAsigna.company
                  : reporteTec.userName + " -  " + reporteTec.userCompany}
              </p>
            </CardFooter>
          </Card>
        </GridItem>
      ));
    }

    //CARGAR SOLICITUDES DE PAUSA
    if (!reporte.solicitudPausa[0] || reporte.solicitudPausa[0].estado !== 1) {
      paintSolicitudPausa = null;
    } else {
      paintSolicitudPausa = (
        <GridItem xs={12} md={12} key={reporte.solicitudPausa[0]._id}>
          <Card key={reporte.solicitudPausa[0]._id}>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <PauseCircleFilledIcon />
              </CardIcon>
              <br />
              <h4 className={classes.cardTitle}>
                {"Solicitud realizada"}
                {" el "}
                {new Date(
                  reporte.solicitudPausa[0].fecha * 1000
                ).toLocaleString()}
              </h4>
            </CardHeader>
            <CardBody>
              <p>
                Solicitud de pausa realizada por{" "}
                {reporte.solicitudPausa[0].userAsigna.name} -{" "}
                {reporte.solicitudPausa[0].userAsigna.company}
              </p>
              <p>
                <b>Razón:</b> {reporte.solicitudPausa[0].comentario}
              </p>
            </CardBody>
            <CardFooter>
              <Grid
                container
                direction="row"
                justifycontent="center"
                alignItems="center"
                justify="center"
              >
                <GridItem item>
                  <Button
                    color="success"
                    className={classes.marginRight}
                    onClick={(event) => {
                      setTextNote(
                        "Solicitud de pausa realizada por " +
                          reporte.solicitudPausa[0].comentario +
                          " fue aceptada"
                      );
                      handleAceptaSolicitudPausa(event);
                    }}
                  >
                    Aceptar
                  </Button>
                </GridItem>
                <GridItem item>
                  <Button
                    color="danger"
                    className={classes.marginRight}
                    onClick={(event) => {
                      setRechazoSolicitudModal(true);
                    }}
                  >
                    Rechazar
                  </Button>
                </GridItem>
              </Grid>
            </CardFooter>
          </Card>
        </GridItem>
      );
    }

    //CARGAR ASIGNACION
    if (!reporte.asignar[0]) {
      paintAsignaciones = null;
    } else {
      paintAsignaciones = reporte.asignar.map((asignar) => (
        <GridItem xs={12} md={12} key={asignar._id}>
          <Card key={asignar._id}>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <AssignmentTurnedIn />
              </CardIcon>
              <br />
              <h4 className={classes.cardTitle}>
                {asignar.handleEstado}
                {" el "}
                {new Date(asignar.fecha * 1000).toLocaleString()}
              </h4>
            </CardHeader>
            <CardBody>
              <p>
                Tiquete asignado a{" "}
                {asignar.hasOwnProperty("user")
                  ? asignar.user.name
                  : asignar.asignacion}
              </p>
            </CardBody>
            <CardFooter>
              <p>
                Asignación realizada por{" "}
                {asignar.hasOwnProperty("userAsigna")
                  ? asignar.userAsigna.name
                  : asignar.userName}{" "}
                -{" "}
                {asignar.hasOwnProperty("userAsigna")
                  ? asignar.userAsigna.company
                  : asignar.userCompany}
              </p>
            </CardFooter>
          </Card>
        </GridItem>
      ));
    }
  }

  return (
    <div>
      <GridContainer>{reporteInfo}</GridContainer>

      <GridContainer>
        <h3>Asignación: </h3>
        {paintAsignaciones}
      </GridContainer>

      {userRole === "1" ? (
        <GridContainer>
          <h3>Solicitud de pausa:</h3>
          {paintSolicitudPausa}
        </GridContainer>
      ) : null}

      <GridContainer>
        <h3>Reportes Técnicos: </h3>
        {paintReportesTec}
      </GridContainer>

      <GridContainer>
        <h3>Notas: </h3>
        {paintNotes}
      </GridContainer>

      {/* MODAL SOLICITUD PAUSA */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={solicitudPausaModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setSolicitudPausaModal(false);
          setTextSolicitudPausa("");
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setSolicitudPausaModal(false);
              setTextSolicitudPausa("");
            }}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Solicitud de pausa del tiquete</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            multiline
            labelText="Escribir comentario"
            id="textNote"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setTextSolicitudPausa(event.target.value);
              },
              type: "text",
              value: textSolicitudPausa,
            }}
          />
          <p>{data.success}</p>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {textSolicitudPausa.length <= 3 ? (
            <Button disabled color="success">
              Guardar
            </Button>
          ) : (
            <Button onClick={handleSolicitudPausaSubmit} color="success">
              Guardar
            </Button>
          )}
          <Button
            onClick={() => {
              setSolicitudPausaModal(false);
              setTextSolicitudPausa("");
            }}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL RECHAZO SOLOCITUD PAUSA */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={rechazoSolicitudModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setRechazoSolicitudModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setRechazoSolicitudModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Rechazar solicitud de Pausa</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            multiline
            labelText="Escribir una razón"
            id="textNote"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setTextNote(event.target.value);
              },
              type: "text",
              value: textNote,
            }}
          />
          <p>{data.success}</p>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {textNote.length <= 3 ? (
            <Button disabled color="success">
              Guardar
            </Button>
          ) : (
            <Button onClick={handleRechazoSolicitudSubmit} color="success">
              Guardar
            </Button>
          )}
          <Button
            onClick={() => setRechazoSolicitudModal(false)}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL NOTA */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={notaModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setNotaModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setNotaModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Crear Nota</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            multiline
            labelText="Escribir comentario"
            id="textNote"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setTextNote(event.target.value);
              },
              type: "text",
              value: textNote,
            }}
          />
          <p>{data.success}</p>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {textNote.length <= 3 ? (
            <Button disabled color="success">
              Guardar
            </Button>
          ) : (
            <Button onClick={handleNotaSubmit} color="success">
              Guardar
            </Button>
          )}
          <Button onClick={() => setNotaModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL REPORTE TECNICO */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={reporteTecModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setReporteTecModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setReporteTecModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Crear Reporte Técnico</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <InputLabel className={classes.label}>
                Materiales requeridos
              </InputLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <CustomInput
                multiline
                // labelText=""
                id="textMateriales"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: true,
                  onChange: (event) => {
                    setTextMateriales(event.target.value);
                  },
                  type: "text",
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={4}>
              <InputLabel className={classes.label}>
                Horas hombre naturales
              </InputLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <CustomInput
                id="textHorasHombre"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    setTextHorasHombre(event.target.value);
                  },
                  type: "number",
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={4}>
              <InputLabel className={classes.label}>
                Horas hombre extra
              </InputLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <CustomInput
                id="textHorasExra"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (event) => {
                    setTextHorasExtra(event.target.value);
                  },
                  type: "number",
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={4}>
              <InputLabel className={classes.label}>
                Otros comentarios
              </InputLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <CustomInput
                multiline
                // labelText="Escribir otros comentarios"
                id="textReporteTec"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  multiline: true,
                  onChange: (event) => {
                    setTextReporteTec(event.target.value);
                  },
                  type: "text",
                }}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {textReporteTec.length <= 3 ? (
            <Button disabled color="success">
              Guardar
            </Button>
          ) : (
            <Button onClick={handleReporteTecSubmit} color="success">
              Guardar
            </Button>
          )}
          <Button
            onClick={() => setReporteTecModal(false)}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL CAMBIAR CATEGORIA */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={categoriaModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setCategoriaModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setCategoriaModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Cambiar categoría</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {/* Añadir opciones */}
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              value={multipleSelect}
              onChange={handleMultiple}
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              inputProps={{
                name: "multipleSelect",
                id: "multiple-select",
              }}
            >
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelectedMultiple,
                }}
                value="1"
              >
                Mantenimiento de edificio
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelectedMultiple,
                }}
                value="2"
              >
                Limpieza
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelectedMultiple,
                }}
                value="3"
              >
                Cafeteria
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelectedMultiple,
                }}
                value="4"
              >
                Técnico de facilidades
              </MenuItem>
            </Select>
          </FormControl>
          <Muted>
            Si el tiquete no corresponde a ninguna categoría, <br />
            proceda a rechazar el tiquete.
          </Muted>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {categoriaOriginal == multipleSelect ? (
            <Button disabled color="success">
              Guardar
            </Button>
          ) : (
            <Button onClick={handleCategoriaSubmit} color="success">
              Guardar
            </Button>
          )}
          {/* <Button onClick={handleCategoriaSubmit} color="success">
              Guardar
            </Button> */}
          <Button
            onClick={() => setCategoriaModal(false)}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
        <DialogActions>
          <Button
            fullWidth
            color="danger"
            onClick={() => setRechazarModal(true)}
            className={classes.marginRight}
          >
            Rechazar Tiquete
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL ASIGNACION */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={asignacionModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setAsignacionModal(false);
          setTextAsignacion([]);
          setTextDuracion("");
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => {
              setAsignacionModal(false);
              setTextAsignacion([]);
              setTextDuracion("");
            }}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Realizar Asignación</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {empresa === "Boston Scientific" ? (
            <FormControl fullWidth className={classes.selectFormControl}>
              <h3 htmlFor="simple-select" className={classes.selectLabel}>
                Usuario*
              </h3>
              {reporte.categoria === 1 ? (
                <FormControl>
                  <Select
                    multiple
                    value={textAsignacion}
                    onChange={(event) => {
                      setTextAsignacion(event.target.value);
                    }}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    inputProps={{
                      name: "asignacionSelect",
                      id: "asignacion-select",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Asignación de usuario*
                    </MenuItem>
                    {users.map((user, x) => (
                      <MenuItem
                        key={user._id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={user._id}
                      >
                        {x + 1} - {user.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <h3 htmlFor="simple-select" className={classes.selectLabel}>
                    Duración de tiempo*
                  </h3>
                  <Select
                    value={textDuracion}
                    onChange={(event) => {
                      setTextDuracion(event.target.value);
                    }}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    inputProps={{
                      name: "asignacionSelect",
                      id: "asignacion-select",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Duración de tiempo
                    </MenuItem>
                    {duraciones.map((duracion) => (
                      <MenuItem
                        key={duracion.value}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={duracion.value}
                      >
                        {duracion.nombre} - ({duracion.duracion})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <FormControl>
                  <CustomInput
                    multiline
                    labelText="Ingresar nombre de usuario"
                    id="textAsignaciom"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      onChange: (event) => {
                        setTextAsignacionU(event.target.value);
                      },
                      type: "text",
                      value: textAsignacionU,
                    }}
                  />
                </FormControl>
              )}
            </FormControl>
          ) : (
            <CustomInput
              multiline
              labelText="Escribir asignacion"
              id="textAsignacion"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                multiline: true,
                onChange: (event) => {
                  setTextAsignacionU(event.target.value);
                },
                type: "text",
              }}
            />
          )}

          <p>{data.success}</p>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={handleAsignacionSubmit} color="success">
            Guardar
          </Button>
          <Button
            onClick={() => {
              setAsignacionModal(false);
              setTextAsignacion([]);
              setTextDuracion("");
            }}
            color="danger"
            simple
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      {/* MODAL RECHAZAR TIQUETE */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={rechazarModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setRechazarModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setRechazarModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Rechazar Tiquete</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            multiline
            labelText="Justificación"
            id="txtJustificacion"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setTxtJustificacion(event.target.value);
              },
              type: "text",
            }}
          />
          {/* <p>{data.success}</p> */}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {txtJustificacion.length <= 3 ? (
            <Button disabled color="success">
              Guardar
            </Button>
          ) : (
            <Button onClick={handleRechazarSubmit} color="success">
              Guardar
            </Button>
          )}
          <Button onClick={() => setRechazarModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* MODAL PAUSAR TIQUETE */}
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={pausarModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setPausarModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setPausarModal(false)}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Pausar Tiquete</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            multiline
            labelText="Escribir razón"
            id="textNote"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              multiline: true,
              onChange: (event) => {
                setTextPausa(event.target.value);
              },
              type: "text",
              value: textPausa,
            }}
          />
          <p>{data.success}</p>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          {textPausa.length <= 3 ? (
            <Button disabled color="success">
              Guardar
            </Button>
          ) : (
            <Button onClick={handlePauseSubmit} color="success">
              Guardar
            </Button>
          )}
          <Button onClick={() => setPausarModal(false)} color="danger" simple>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        place="tc"
        color="success"
        icon={DoneAllIcon}
        message={txtNotificacion}
        open={tc}
        closeNotification={() => setTC(false)}
        close
      />
    </div>
  );
}

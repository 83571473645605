/*eslint-disable*/
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRange } from 'react-date-range';

// excel
import ReactExport from "react-data-export";
// react plugin for creating charts
import ChartistGraph from "react-chartist";


// //Modal
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import TextField2 from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";

// import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Close from "@material-ui/icons/Close";

// core components
// import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Sending from "../../common/sending.js";

import ReactTable from "../../components/ReactTable/ReactTable.js";

//DataPicker components
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.js";

import setAuthToken from "../../utils/setAuthToken";

import { simpleBarChart, pieChart } from "variables/charts.js";

// @mui
import { styled } from '@mui/material/styles';


import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";


//Excel req
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);

const CssTextField = styled(TextField2)({
  '& label.Mui-focused': {
    color: '#4caf50',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#4caf50',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'current',
    },
    // '&:hover fieldset': {
    //   borderColor: 'yellow',
    // },
    '&.Mui-focused fieldset': {
      borderColor: '#4caf50',
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Charts() {
  const classes = useStyles();
  const classes2 = useStyles2();

  const [data, setData] = useState({ reportes: [] });
  const [users, setUsers] = useState([]);
  const [infraSeg, setInfraSeg] = React.useState();
  const [limpiSeg, setLimpiSeg] = React.useState();
  const [cafeSeg, setCafeSeg] = React.useState();
  const [solicitante, setSolicitante] = React.useState();
  const [horasHombre, setHorasHombre] = React.useState();
  const [horasHombreExtra, setHorasHombreExtra] = React.useState();
  const [loading, setLoading] = useState(false);
  const [desde, setDesde] = React.useState();
  const [hasta, setHasta] = React.useState();
  const [cantidadDias, setCantidadDias] = useState((new Date()).getDate());
  const [dataGraficosPie, setDataGraficoPie] = useState({
    labels: [],
    series: [],
  });
  const [dataGraficosBar, setDataGraficoBar] = useState({
    labels: [],
    series: [],
  });

  const [dataGraficosBarSLA, setDataGraficosBarSLA] = useState({
    labels: [],
    series: [],
  });

  const [dataTablaSLA, setdataTablaSLA] = useState([]);
  const [tiquetesSLA, setTiquetesSLA] = useState([0, 0, 0, 0]);
  const [SLATerminados, setSLATerminados] = useState([0, 0, 0, 0]);
  const [SLAProceso, setSLAProceso] = useState([0, 0, 0, 0]);
  const [SLAPausados, setSLAPausados] = useState([0, 0, 0, 0]);

  const [value, setValue] = React.useState([null, null]);

  const [multipleSelectTecnico, setMultipleSelectTecnico] = useState([]);

  const [rangeModal, setRangeModal] = useState(false);

  const userRole = localStorage.userConfig;

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);

  const handleFrom = (e) => {
    setDesde(e.unix());
  };
  const handleTo = (e) => {
    setHasta(e.unix());
  };

  const handleDate = (e) => {
    e[0] !== null ?
      setDesde(e[0].getTime() / 1000)
      :
      setDesde('');

    e[1] !== null ?
      setHasta(e[1].getTime() / 1000)
      :
      setHasta('');

  };

  useEffect(() => {
    setLoading(true);
    setAuthToken(localStorage.jwtToken);
    const fetchData = async () => {
      let result;
      let resultPlanilla;

      const hoy = new Date();
      hoy.setDate(1);
      // hoy.setMonth(3);//quitar esta linea en produccion
      let desde = Math.floor(new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()).getTime() / 1000);
      let hasta = Math.floor(new Date() / 1000);

      if (userRole === "1") {
        result = await axios("https://epstool.com/api/reporte/all");
        // result = await axios("http://localhost:5005/api/reporte/all");

        resultPlanilla = await axios(`https://epstool.com/api/reporte/sla-planilla/${desde}/${hasta}`);
        // resultPlanilla = await axios(`http://localhost:5005/api/reporte/sla-planilla/${desde}/${hasta}`);

      } else if (userRole === "2") {
        const decoded = jwt_decode(localStorage.jwtToken);
        result = await axios(`https://epstool.com/api/reporte/user/${decoded.id}`);
        // result = await axios(`http://localhost:5005/api/reporte/user/${decoded.id}`);

        resultPlanilla = await axios(`https://epstool.com/api/reporte/sla-planilla/${decoded.id}/${decoded.name}/${desde}/${hasta}`);
        // resultPlanilla = await axios(`http://localhost:5005/api/reporte/sla-planilla/${decoded.id}/${decoded.name}/${desde}/${hasta}`);
      }
      // const result = await axios("/api/reporte/all");

      const resultUsers = await axios.get(
        `https://epstool.com/api/users/userstec`
        // `http://localhost:5005/api/users/userstec`
      );

      setUsers(resultUsers.data);
      setdataTablaSLA(resultPlanilla.data);

      setData(result.data);
      sumaGraphPie(result.data);
      sumaGraphBar(result.data);

      calculaSLA(result);
    };
    fetchData();
    setLoading(false);
  }, []);

  const calculaSLA = (result) => {

    let tempSLAData = [0, 0, 0, 0];
    let tempSLATerminados = [0, 0, 0, 0];
    let tempSLAProceso = [0, 0, 0, 0];
    let tempSLAPausados = [0, 0, 0, 0];
    let tempDataTablaSLA = [];


    result.data.forEach((element) => {
      switch (element.duracion) {
        case 1:
          tempSLAData[0]++;
          switch (element.estado) {
            case 1:
              tempSLAProceso[0]++;
              break;
            case 2:
              tempSLATerminados[0]++;
              break;
            case 3:
              tempSLAPausados[0]++;
              break;
          }
          break;
        case 2:
          tempSLAData[1]++;
          switch (element.estado) {
            case 1:
              tempSLAProceso[1]++;
              break;
            case 2:
              tempSLATerminados[1]++;
              break;
            case 3:
              tempSLAPausados[1]++;
              break;
          }
          break;
        case 3:
          tempSLAData[2]++;
          switch (element.estado) {
            case 1:
              tempSLAProceso[2]++;
              break;
            case 2:
              tempSLATerminados[2]++;
              break;
            case 3:
              tempSLAPausados[2]++;
              break;
          }
          break;
        case 4:
          tempSLAData[3]++;
          switch (element.estado) {
            case 1:
              tempSLAProceso[3]++;
              break;
            case 2:
              tempSLATerminados[3]++;
              break;
            case 3:
              tempSLAPausados[3]++;
              break;
          }
          break;
      }
    });

    setTiquetesSLA(tempSLAData);
    setSLATerminados(tempSLATerminados);
    setSLAPausados(tempSLAPausados);
    setSLAProceso(tempSLAProceso);

    sumaGraphBarSLA(tempSLAData, tempSLATerminados, tempSLAPausados, tempSLAProceso);

  }

  const handleDates = async (event) => {
    event.preventDefault();
    setLoading(true);

    let result;
    let resultPlanilla;
    let temPlanillaData = [];


    if (localStorage.userConfig === "2") {
      result = await axios.get(
        // `http://localhost:5005/api/reporte/date/${desde}/${hasta}/${jwt_decode(localStorage.jwtToken).id}`
        `https://epstool.com/api/reporte/date/${desde}/${hasta}/${jwt_decode(localStorage.jwtToken).id}`
      );

      resultPlanilla = await axios(`https://epstool.com/api/reporte/sla-planilla/${jwt_decode(localStorage.jwtToken).id}/${jwt_decode(localStorage.jwtToken).name}/${desde}/${hasta}`);
      // resultPlanilla = await axios(`http://localhost:5005/api/reporte/sla-planilla/${jwt_decode(localStorage.jwtToken).id}/${jwt_decode(localStorage.jwtToken).name}/${desde}/${hasta}`);
      resultPlanilla = resultPlanilla.data;
    } else if (localStorage.userConfig === "1") {
      result = await axios.get(
        // `http://localhost:5005/api/reporte/date/${desde}/${hasta}`
        `https://epstool.com/api/reporte/date/${desde}/${hasta}`
      );

      console.log('ResultData', result)


      if (multipleSelectTecnico.length > 0) {
        let tempData = [];

        for (let i = 0; i < multipleSelectTecnico.length; i++) {
          let name = (users.filter(usuario => usuario._id == multipleSelectTecnico[i]))[0].name;

          resultPlanilla = (await axios(`https://epstool.com/api/reporte/sla-planilla/${event.target.value[i]}/${name}/${desde}/${hasta}`)).data;
          // resultPlanilla = (await axios(`http://localhost:5005/api/reporte/sla-planilla/${multipleSelectTecnico[i]}/${name}/${desde}/${hasta}`)).data;

          if (i === 0) {
            temPlanillaData = resultPlanilla;
          } else {

            for (let j = 0; j < temPlanillaData.length; j++) {
              temPlanillaData[j].sla0 += resultPlanilla[j].sla0;
              temPlanillaData[j].sla1 += resultPlanilla[j].sla1;
              temPlanillaData[j].sla2 += resultPlanilla[j].sla2;
              temPlanillaData[j].sla3 += resultPlanilla[j].sla3;
            }

          }
        }

        resultPlanilla = temPlanillaData;
        console.log(result.data)

        result.data.forEach((element) => {
          for (let i = 0; i < multipleSelectTecnico.length; i++) {

            let name = (users.filter(usuario => usuario._id == multipleSelectTecnico[i]))[0].name;

            console.log('ids: ', multipleSelectTecnico[i], element.asignar[0].asignacion)

            for (let j = 0; j < element.asignar.length; j++) {
              if (multipleSelectTecnico[i] === element.asignar[j].asignacion || name === element.asignar[j].asignacion) {
                tempData.push(element);
              }
            }

          }
        })
        result.data = tempData;
      } else {
        resultPlanilla = (await axios(`https://epstool.com/api/reporte/sla-planilla/${desde}/${hasta}`)).data;
        // resultPlanilla = (await axios(`http://localhost:5005/api/reporte/sla-planilla/${desde}/${hasta}`)).data;
      }
    }

    setData(result.data);
    setdataTablaSLA(resultPlanilla);

    calculaSLA(result);
    sumaGraphPie(result.data);
    sumaGraphBar(result.data);
    // setCantidadDias((hasta - desde) / 86400);
    setLoading(false);

  };

  const handleUsers = async (event) => {
    event.preventDefault();
    setLoading(true);

    let result;
    let tempData = [];
    let temPlanillaData = [];

    let Tempdesde;
    let Temphasta;

    if (!desde && !hasta) {
      const hoy = new Date();
      hoy.setDate(1);
      // hoy.setMonth(3);//quitar esta linea en produccion
      Tempdesde = Math.floor(new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate()).getTime() / 1000);
      Temphasta = Math.floor(new Date() / 1000);
    } else {
      Tempdesde = desde;
      Temphasta = hasta;
    }

    if (event.target.value.length > 0) {

      for (let i = 0; i < event.target.value.length; i++) {
        result = await axios.get(
          `https://epstool.com/api/reporte/user/${event.target.value[i]}`
          // `http://localhost:5005/api/reporte/user/${event.target.value[i]}`
        );

        result.data = result.data.filter(data => data.fechaCreacion >= Tempdesde && data.fechaCreacion <= Temphasta)

        let name = (users.filter(usuario => usuario._id == event.target.value[i]))[0].name;
        let resultPlanilla = [];

        resultPlanilla = await axios(`https://epstool.com/api/reporte/sla-planilla/${event.target.value[i]}/${name}/${Tempdesde}/${Temphasta}`);
        // resultPlanilla = await axios(`http://localhost:5005/api/reporte/sla-planilla/${event.target.value[i]}/${name}/${Tempdesde}/${Temphasta}`);

        // }

        if (i === 0) {
          temPlanillaData = resultPlanilla.data;
        } else {

          for (let j = 0; j < temPlanillaData.length; j++) {
            temPlanillaData[j].sla0 += resultPlanilla.data[j].sla0;
            temPlanillaData[j].sla1 += resultPlanilla.data[j].sla1;
            temPlanillaData[j].sla2 += resultPlanilla.data[j].sla2;
            temPlanillaData[j].sla3 += resultPlanilla.data[j].sla3;
          }

        }

        tempData = tempData.concat(result.data);
        // console.log('tempData', tempData);

      };
      result.data = tempData;
    } else {
      result = await axios("https://epstool.com/api/reporte/all");
      // result = await axios("http://localhost:5005/api/reporte/all");

      let resultPlanilla = [];

      resultPlanilla = (await axios(`https://epstool.com/api/reporte/sla-planilla/${Tempdesde}/${Temphasta}`)).data;
      // resultPlanilla = (await axios(`http://localhost:5005/api/reporte/sla-planilla/${Tempdesde}/${Temphasta}`)).data;

      // }
      console.log('resultPlanilla', resultPlanilla);
      temPlanillaData = resultPlanilla;
    }

    setData(result.data);
    setdataTablaSLA(temPlanillaData);

    calculaSLA(result);

    sumaGraphPie(result.data);
    sumaGraphBar(result.data);
    setLoading(false);
  };

  const handleMultipleTecnico = async (event) => {
    setMultipleSelectTecnico(event.target.value);
    await handleUsers(event);
  };

  const sumaGraphPie = (info) => {
    let nuevo = 0;
    let proceso = 0;
    let pausado = 0;
    let terminado = 0;
    let solicitantes = 0;
    let horasHombre = 0;
    let horasHombreExtra = 0;
    info.forEach((element) => {
      if (element.email) {
        solicitantes = solicitantes + 1;
      }
      switch (element.estado) {
        case 0:
          nuevo = nuevo + 1;
          break;
        case 1:
          proceso = proceso + 1;
          break;
        case 2:
          terminado = terminado + 1;
          if (element.reporteTecnico[0]) {
            element.reporteTecnico[0].horasHombreNormal
              ? (horasHombre =
                horasHombre + element.reporteTecnico[0].horasHombreNormal)
              : (horasHombre = horasHombre + 0);
            element.reporteTecnico[0].horasHombreExtra
              ? (horasHombre =
                horasHombre + element.reporteTecnico[0].horasHombreExtra)
              : (horasHombre = horasHombre + 0);
          }
          break;
        case 3:
          pausado++;
          break;
        default:
          break;
      }
    });
    const dataObj = {
      labels: [nuevo.toString(), proceso.toString(), terminado.toString(), pausado.toString()],
      series: [nuevo, proceso, terminado, pausado],
    };
    setHorasHombre(horasHombre);
    setHorasHombreExtra(horasHombreExtra);
    setSolicitante(solicitantes);
    setDataGraficoPie(dataObj);
  };

  const sumaGraphBar = (info) => {
    let infraestructura = 0;
    let infraestructuraNuevo = 0;
    let infraestructuraProceso = 0;
    let infraestructuraPausados = 0;
    let infraestructuraTerminado = 0;
    let infraestructuraSegundosTerminado = 0;
    let limpieza = 0;
    let limpiezaNuevo = 0;
    let limpiezaProceso = 0;
    let limpiezaPausado = 0;
    let limpiezaTerminado = 0;
    let limpiezaSegundosTerminado = 0;
    let cafeteria = 0;
    let cafeteriaNuevo = 0;
    let cafeteriaProceso = 0;
    let cafeteriaPausado = 0;
    let cafeteriaTerminado = 0;
    let cafeteriaSegundosTerminado = 0;
    let facilidades = 0;
    let facilidadesNuevo = 0;
    let facilidadesProceso = 0;
    let facilidadesPausado = 0;
    let facilidadesTerminado = 0;
    let facilidadesSegundosTerminado = 0;

    info.forEach((element) => {
      switch (element.categoria) {
        case 1:
          infraestructura = infraestructura + 1;
          switch (element.estado) {
            case 0:
              infraestructuraNuevo = infraestructuraNuevo + 1;
              break;
            case 1:
              infraestructuraProceso = infraestructuraProceso + 1;
              break;
            case 2:
              infraestructuraTerminado = infraestructuraTerminado + 1;
              infraestructuraSegundosTerminado =
                infraestructuraSegundosTerminado +
                (element.tiempoAProceso + element.tiempoATerminado);
              break;
            case 3:
              infraestructuraPausados++;
              break;
            default:
              break;
          }
          break;
        case 2:
          limpieza = limpieza + 1;
          switch (element.estado) {
            case 0:
              limpiezaNuevo = limpiezaNuevo + 1;
              break;
            case 1:
              limpiezaProceso = limpiezaProceso + 1;
              break;
            case 2:
              limpiezaTerminado = limpiezaTerminado + 1;
              limpiezaSegundosTerminado =
                limpiezaSegundosTerminado +
                (element.tiempoAProceso + element.tiempoATerminado);
              break;
            case 3:
              limpiezaPausado++;
              break;
            default:
              break;
          }
          break;
        case 3:
          cafeteria = cafeteria + 1;
          switch (element.estado) {
            case 0:
              cafeteriaNuevo = cafeteriaNuevo + 1;
              break;
            case 1:
              cafeteriaProceso = cafeteriaProceso + 1;
              break;
            case 2:
              cafeteriaTerminado = cafeteriaTerminado + 1;
              cafeteriaSegundosTerminado =
                cafeteriaSegundosTerminado +
                (element.tiempoAProceso + element.tiempoATerminado);
              break;
            case 3:
              cafeteriaPausado++;
              break;
            default:
              break;
          }
          break;
        case 4:
          facilidades = facilidades + 1;
          switch (element.estado) {
            case 0:
              facilidadesNuevo = facilidadesNuevo + 1;
              break;
            case 1:
              facilidadesProceso = facilidadesProceso + 1;
              break;
            case 2:
              facilidadesTerminado = facilidadesTerminado + 1;
              facilidadesSegundosTerminado =
                facilidadesSegundosTerminado +
                (element.tiempoAProceso + element.tiempoATerminado);
              break;
            case 3:
              facilidadesPausado++;
              break;
            default:
              break;
          }
          break;
        default:
          infraestructura = infraestructura + 1;
          break;
      }
    });
    const dataObj = {
      labels: ["Mant. Edificio", "Limpieza", "Tec. Facilidades", "Cafeteria"],
      series: [
        [infraestructura, limpieza, facilidades, cafeteria],
        [infraestructuraNuevo, limpiezaNuevo, facilidadesNuevo, cafeteriaNuevo],
        [infraestructuraProceso, limpiezaProceso, facilidadesProceso, cafeteriaProceso],
        [infraestructuraTerminado, limpiezaTerminado, facilidadesTerminado, cafeteriaTerminado],
        [infraestructuraPausados, limpiezaPausado, facilidadesPausado, cafeteriaPausado],
      ],
    };
    let promInfra = infraestructuraSegundosTerminado / infraestructuraTerminado;
    if (isNaN(promInfra)) {
      promInfra = 0;
    }
    let promLimpi = limpiezaSegundosTerminado / limpiezaTerminado;
    if (isNaN(promLimpi)) {
      promLimpi = 0;
    }
    let promCafe = cafeteriaSegundosTerminado / cafeteriaTerminado;
    if (isNaN(promCafe)) {
      promCafe = 0;
    }
    setInfraSeg(promInfra);
    setLimpiSeg(promLimpi);
    setCafeSeg(promCafe);
    setDataGraficoBar(dataObj);
  };

  const sumaGraphBarSLA = (tempSLAData, tempSLATerminados, tempSLAPausados, tempSLAProceso) => {

    setDataGraficosBarSLA({
      labels: ['SLA0', 'SLA1', 'SLA2', 'SLA3'],
      series: [
        tempSLAData,
        tempSLAProceso,
        tempSLAPausados,
        tempSLATerminados,
      ]
      ,
      // series: [
      //   tempSLAData,
      //   tempSLAProceso,
      //   tempSLAPausados,
      //   tempSLATerminados,
      // ]
    });
  }

  let tSLA;

  if (data === null) {
    tSLA = null;
  } else {
    tSLA = (
      <Card>
        <CardHeader color="success" icon>
          <CardIcon color="success">
            <SpeakerNotesIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Listado de estadísticas</h4>
        </CardHeader>
        <CardBody>
          <ReactTable
            columns={[
              {
                Header: "Estado",
                accessor: "estado",
              },
              {
                Header: "SLA 0",
                accessor: "sla0",
              },
              {
                Header: "SLA 1",
                accessor: "sla1",
              },
              {
                Header: "SLA 2",
                accessor: "sla2",
              },
              {
                Header: "SLA 3",
                accessor: "sla3",
              },
              {
                Header: "Total",
                accessor: "total",
              },
            ]}
            data={dataTablaSLA}
          />
        </CardBody>
      </Card>
    );
  }

  let graficoBarSLA;
  let graficoPie;
  let graficoBar;

  graficoBarSLA = (
    <Card>
      <CardHeader color="warning" icon>
        <CardIcon color="info">
          <Timeline />
        </CardIcon>
        {console.log(simpleBarChart.options.plugins)}
        <h4 className={classes.cardIconTitle}>Cantidad de reportes por categoría de SLA</h4>
      </CardHeader>
      <CardBody>
        <ChartistGraph
          data={
            dataGraficosBarSLA
          }
          type="Bar"
          options={simpleBarChart.options}
        // listener={colouredLinesChart.animation}
        />
        {

        }
      </CardBody>
      <CardFooter stats className={classes.cardFooter}>
        <h6 className={classes.legendTitle}>Legend</h6>
        <i className={"fas fa-circle " + classes.info} /> Total{` `}
        <i className={"fas fa-circle " + classes.nuevo} /> Proceso
        {` `}
        <i className={"fas fa-circle " + classes.proceso} /> Pausados
        {` `}
        <i className={"fas fa-circle " + classes.terminado} /> Terminado
      </CardFooter>
    </Card>
  );

  if (data === undefined || loading === true) {
    // graficoPie = "Cargando...";
    graficoPie = (
      <div className={classes.load}>
        <Sending />
        <h4>Cargando reportes...</h4>
      </div>
    );
  } else {
    graficoPie = (
      <Card>
        <CardHeader color="danger" icon>
          <CardIcon color="danger">
            <Timeline />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Estado de los reportes</h4>
        </CardHeader>
        <CardBody>
          <ChartistGraph
            data={dataGraficosPie}
            type="Pie"
            options={pieChart.options}
          />
        </CardBody>
        <CardFooter stats className={classes.cardFooter}>
          <h6 className={classes.legendTitle}>Legend</h6>
          <i className={"fas fa-circle " + classes.nuevo} /> Nuevo{` `}
          <i className={"fas fa-circle " + classes.proceso} /> Proceso {` `}
          <i className={"fas fa-circle " + classes.terminado} /> Terminado{` `}
          <i className={"fas fa-circle " + classes.pausadoPie} /> Pausado
          {` `}
        </CardFooter>
      </Card>
    );
    graficoBar = (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <Timeline />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Cantidad de reportes por categoría de área</h4>
        </CardHeader>
        <CardBody>
          <ChartistGraph
            data={dataGraficosBar}
            type="Bar"
            options={simpleBarChart.options}
          // listener={colouredLinesChart.animation}
          />
        </CardBody>
        <CardFooter stats className={classes.cardFooter}>
          <h6 className={classes.legendTitle}>Legend</h6>
          <i className={"fas fa-circle " + classes.info} /> Total{` `}
          <i className={"fas fa-circle " + classes.nuevo} /> Nuevo
          {` `}
          <i className={"fas fa-circle " + classes.proceso} /> Proceso
          {` `}
          <i className={"fas fa-circle " + classes.terminado} /> Terminado
          {` `}
          <i className={"fas fa-circle " + classes.pausado} /> Pausado

        </CardFooter>
      </Card>
    );
  }

  let buttonExcel;
  let buttonCalcular;
  let resumen;
  if (data === undefined) {
    buttonExcel = (
      <Button
        color="success"
        fullWidth
        disabled
        className={classes.marginRight}
      >
        Exportar Excel
      </Button>
    );
    resumen = "";
  } else {
    let dataForExcel = [];

    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      let excelEstado;
      let excelCategoria;
      switch (element.categoria) {
        case 1:
          excelCategoria = "Infraestructura";
          break;
        case 2:
          excelCategoria = "Limpieza";
          break;
        case 3:
          excelCategoria = "Cafeteria";
          break;
        case 4:
          excelCategoria = "Infraestructura";
          break;
        default:
          break;
      }

      switch (element.estado) {
        case 0:
          excelEstado = "Nuevo";
          break;
        case 1:
          excelEstado = "En proceso";
          break;
        case 2:
          excelEstado = "Terminado";
          break;
        case 3:
          excelEstado = "Pausado";
          break;
        default:
          break;
      }
      let excelZona;
      if (element.diferenteZona === "No") {
        excelZona = element.zona;
      } else {
        excelZona = element.diferenteZona;
      }
      const fechaExcel = new Date(
        element.fechaCreacion * 1000
      ).toLocaleString();

      dataForExcel.push({
        codigo: `#${element.codigoReporte}`,
        fechaCreacion: fechaExcel,
        zona: excelZona,
        categoria: excelCategoria,
        estado: excelEstado,
        detalle: element.detalle,
      });
    }
    let hoy = new Date();
    buttonExcel = (
      <ExcelFile
        filename={`Reporte ${hoy.getDate()}/${hoy.getMonth() +
          1}/${hoy.getFullYear()} ${hoy.getHours()}:${hoy.getMinutes()}`}
        element={
          <Button color="success" fullWidth className={classes.marginRight}>
            Exportar Excel
          </Button>
        }
      >
        <ExcelSheet data={dataForExcel} name="Reportes">
          <ExcelColumn label="Código" value="codigo" />
          <ExcelColumn label="Fecha" value="fechaCreacion" />
          <ExcelColumn label="Zona" value="zona" />
          <ExcelColumn label="Categoria" value="categoria" />
          <ExcelColumn label="Estado" value="estado" />
          <ExcelColumn label="Detalle" value="detalle" />
        </ExcelSheet>

        <ExcelSheet data={dataTablaSLA} name="SLA">
          <ExcelColumn label="Estado" value="estado" />
          <ExcelColumn label="SLA 1" value="sla1" />
          <ExcelColumn label="SLA 2" value="sla2" />
          <ExcelColumn label="SLA 3" value="sla3" />
          <ExcelColumn label="Total" value="total" />
        </ExcelSheet>
      </ExcelFile>
    );

    resumen = (
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardBody>
              <p className={classes.cardCategory}>
                Total tiquetes / Terminados
              </p>
              <br />
              <h3 className={classes.cardTitle}>
                {/* 50/30 <small>GB</small> */}
                {Object.keys(data).length} / {dataGraficosPie.series[2]}
              </h3>
            </CardBody>
            {/* <CardFooter stats>
              <div className={classes.stats}>
                <Warning />
                Get more space
              </div>
            </CardFooter> */}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardBody>
              <p className={classes.cardCategory}>
                Horas promedio en terminar tiquetes
              </p>
              <br />
              <h3 className={classes.cardTitle}>
                {/* 50/30 <small>GB</small> */}
                {/* 50 / 30 */}
                {parseFloat((infraSeg + limpiSeg + cafeSeg) / 3600).toFixed(2)}
                {/* {(infraSeg + limpiSeg + cafeSeg) / 60 / 60} */}
              </h3>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardBody>
              <p className={classes.cardCategory}>
                Horas hombre registradas / Horas extra
              </p>
              <br />
              <h3 className={classes.cardTitle}>
                {/* 50/30 <small>GB</small> */}
                {horasHombre} / {horasHombreExtra}
              </h3>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      // <ul>
      //   <li>Segundos infra: {infraSeg}</li>
      //   <li>Segundos limpieza: {limpiSeg}</li>
      //   <li>Segundos cafeteria: {cafeSeg}</li>
      // </ul>
    );
  }

  return (
    <div>
      <GridContainer>
        <Card>
          <CardHeader>
            <h3 className={classes.cardIconTitle}>
              Estadísticas de los últimos {cantidadDias} días
            </h3>
          </CardHeader>
          <CardBody>
            <GridContainer justify="center">
              <GridItem xs={12} md>
                <GridContainer>
                  <GridItem xs={12} md>
                    <InputLabel className={classes.label}>
                      Búsqueda por fecha
                    </InputLabel>
                    <br />
                    <GridContainer>
                      <GridItem xs={12} md>

                        <CssTextField
                          fullWidth
                          label="Desde"
                          id="custom-css-outlined-input"
                          value={
                            desde ? (new Date(desde * 1000)).toLocaleDateString() : ''
                          }
                          onClick={() => setRangeModal(true)}
                          InputProps={{
                            endAdornment: <InputAdornment position="end"><DateRangeIcon /></InputAdornment>
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} md>
                        <CssTextField
                          fullWidth
                          label="Hasta"
                          id="custom-css-outlined-input"
                          value={
                            hasta ? (new Date(hasta * 1000)).toLocaleDateString() : ''
                          }
                          onClick={() => setRangeModal(true)}
                          InputProps={{
                            endAdornment: <InputAdornment position="end"><DateRangeIcon /></InputAdornment>
                          }}
                        />

                      </GridItem>
                      <GridItem xs={12} md>
                        {!desde || !hasta ? (
                          <Button
                            disabled
                            fullWidth
                            color="success"
                            onClick={handleDates}
                            className={classes.marginRight}
                          >
                            Calcular
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            color="success"
                            onClick={handleDates}
                            className={classes.marginRight}
                          >
                            Calcular
                          </Button>
                        )}
                      </GridItem>
                      <GridItem xs={12} md>
                        {buttonExcel}

                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer>

                  {/* Busqueda por Tecnico */}
                  {localStorage.userConfig === '1' ?
                    <GridItem xs={12} md={3}>
                      <InputLabel className={classes2.label}>
                        Búsqueda por Técnico
                      </InputLabel>
                      <br />
                      <FormControl fullWidth className={classes2.selectFormControl}>
                        <Select
                          multiple
                          value={multipleSelectTecnico}
                          onChange={handleMultipleTecnico}
                          MenuProps={{ className: classes2.selectMenu }}
                          classes={{ select: classes2.select }}
                          inputProps={{
                            name: "multipleSelect",
                            id: "multiple-select",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes2.selectMenuItem,
                            }}
                          >
                            Selección de Técnico
                          </MenuItem>
                          {users.map((user, x) => (
                            <MenuItem
                              key={user._id}
                              classes={{
                                root: classes2.selectMenuItem,
                                selected: classes2.selectMenuItemSelected,
                              }}
                              value={user._id}
                            >
                              {user.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    :
                    ''
                  }

                </GridContainer>

                <br />

              </GridItem>



            </GridContainer>
          </CardBody>
        </Card >
        {resumen}
        {/* <Card>
          <CardHeader>Resumen</CardHeader>
          <CardBody>{resumen}</CardBody>
        </Card> */}
      </GridContainer >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {tSLA}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {graficoBarSLA}
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          {graficoBar}
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          {graficoPie}
        </GridItem>
        {/* MODAL RANGE DATE */}
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal,
          }}
          open={rangeModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            setRangeModal(false);
          }}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => {
                setRangeModal(false);
              }
              }
            >
              <Close className={classes.modalClose} />
            </Button>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <DateRange
              editableDateInputs={true}
              onChange={(item) => {
                setState([item.selection]);
                handleDate([item.selection.startDate, item.selection.endDate]);
              }}
              moveRangeOnFirstSelection={false}
              ranges={state}
            />
          </DialogContent>
          <DialogActions className={classes.modalFooter} style={{ display: 'flex', justifyContent: 'center', }}>
            <Button onClick={() => { setRangeModal(false) }} color="" style={{ backgroundColor: 'rgb(61, 145, 255)' }}>
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </GridContainer>
    </div >
  );
}
